<template>
    <div class="cont-table" id="approvazioneGrid">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_approvazione">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>N.</th>
                                        <th class='all' id="col_data">Trasferta</th>
                                        <th class='all'>Utente</th>
                                        <th class='desktop'>Descrizione</th>
                                        <th class='desktop'>Azienda</th>
                                        <th class='desktop'>Progetto</th>
                                        <th class='all' style="text-align:right;padding-right:5px">Importo (€)</th>
                                        <th class='all' style="text-align:center">Tipo</th>
                                        <th class='all' style="text-align:center">Stato</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                     
                                        <th class='all col_footer'>Totale (€)</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='desktop col_footer'>&nbsp;</th>

                                        <th class='all col_footer_right' id="v_tot_importo">0,00</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                    </tr>


                                </tfoot>


                                <!-- <tfoot>

                                    <tr>
                                        <th colspan="10">
                                        </th>
                                    </tr>
                                </tfoot> -->

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="conttipi">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetTypes">
                        <v-sheet class="text-center" height="530px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">
                                <v-btn class='closeTypesSheet' icon @click="sheetTypes = !sheetTypes">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>


                            <div class="py-3 c_title_tipi">
                                Tipi
                            </div>

                            <v-list-item v-for="tile in allTypes" :key="tile.title" @click="changeType(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar :size="tile.sizeAvatar" tile>

                                        <v-img alt="" contain :src="tile.img" :max-width="tile.width"
                                            :max-height="tile.height" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>

        <div class="contstati">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetStatus">
                        <v-sheet class="text-center" height="430px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                            </div>

                            <div class="py-3 c_title_stati">
                                Stati
                            </div>

                            <v-list-item v-for="tile in allStatus" :key="tile.title" @click="filterStatus(tile.title)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>


        <div class="contcdc">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCDC" class="vcdcsheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetCDC" fixed style="position:fixed;">
                                CDC
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetCDC = !sheetCDC">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCDC">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCDC" :key="item.projectid"
                                        @click="manageClickCDC(item)">

                                        <v-img alt="CDC Logo" class="  " contain
                                            src="@/assets/icon_cdc_easyer_50_drop.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemCDC'>{{ item.projectname }} ({{ item.notes
                                            }})</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>





        <div class="contcambiastati">

            <template>
                <div class="text-center">

                    <v-bottom-sheet v-model="sheetChangeStatus">
                        <v-sheet class="text-center" height="420px" style="padding-bottom:100px;">

                            <div style="text-align:right!important">

                                <v-btn class='closeChangeStatusSheet' icon
                                    @click="sheetChangeStatus = !sheetChangeStatus">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>


                            </div>


                            <div class="py-3 c_title_change_status">
                                Cambia Stato In
                            </div>

                            <v-list-item v-for="tile in allChangeStatus" :key="tile.title"
                                @click="changeStatus(tile.id)">
                                <v-list-item-avatar>

                                    <v-avatar size="25px" tile>

                                        <v-img alt="" contain :src="tile.img" max-width="25" max-height="25" />

                                    </v-avatar>

                                </v-list-item-avatar>
                                <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                    tile.title
                                }}</v-list-item-title>
                            </v-list-item>

                        </v-sheet>
                    </v-bottom-sheet>

                </div>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/easyer_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupcalculator" style="display:none">

            <template>
                <modal name="popupCalculator" :clickToClose="false" :width="350" :height="303">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left:10px;margin-top:10px!important;font-weight:bold">

                            Totale Spese

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display:flex;margin-left:10px">

                            <div style="width:100px">Spese Selezionate</div>

                            <div id="divFattSel"
                                style="font-weight:bold;min-width:225px;height:140px;text-align:right;overflow-y: auto;">
                            </div>

                        </v-col>



                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="display:flex;margin-left:10px">

                            <div style="width:100px">Totale (€)</div>

                            <div id="divTotSel" style="font-weight:bold;min-width:225px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-cancel-calculator" contain
                                    src="@/assets/btn_cancel3_easyer.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Chiudi"
                                    @click="btnCancelCalculator" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Da" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" v-model="dataA" label="A" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelPeriod" />


                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetPeriod" />

                                <v-img alt="" class="btn-confirm-date-from" contain
                                    src="@/assets/btn_confirm3_easyer.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmPeriod" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupviewfile" style="display:none">

            <template>
                <modal name="popupViewFile" id="pViewFile" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi Allegato

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <iframe id="iframe-pdf" style="width: 97%;
    height: 50vh;"></iframe>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_easyer.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupviewfilemobile" style="display:none">

            <template>
                <modal name="popupViewFileMobile" :clickToClose="false" :width="360" :height="659">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi Allegato

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <iframe id="iframe-pdf" style="margin-left: 10px;
width: 94%;
height: 57vh;"></iframe>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_easyer.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFileMobile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupviewfilepdf" style="display:none">

            <template>
                <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi Allegato

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
justify-items: center;
justify-content: center;
align-items: center;">

                            <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                <div id="container-view-pdf"></div>

                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_easyer.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFilePdf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetUtenti" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headersheetUtenti" fixed style="position:fixed;">
                                Utenti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetUtenti = !sheetUtenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                        id="fldTextSearchAzi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredUsers" :key="item.login"
                                        @click="manageClickUtenti(item)">

                                        <v-img alt="" src="@/assets/users_menu2.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-easyer.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetProgetti" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Progetti
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetProgetti = !sheetProgetti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchProgetti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredProgetti"
                                        :key="item.projectid" @click="manageClickProgetti(item)">

                                        <v-img alt="" src="@/assets/icon_cdc_easyer_50.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.projectname }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


    </div>
</template>

<script>
import apispese from "../utils/spese/apispese";
import apiapprovazione from "../utils/approvazione/apiapprovazione";

import {
    bus
} from "../main";
import router from ".././router";

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';


/* import $ from 'jquery'; */

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        window.curSrcText = "";

        window.dataDa = "";
        window.dataA = "";

        window.selUser = "";


        window.selProgetto = "";


        var pointerVue = this;

        window.perpage = 100;
        bus.$off("bottomIconClickEvent");

        window.curStatus = 4; // di default stato da approvare

        // var b = window.$cookies.get("b");
        // var $rotta = "";

        pointerVue.$root.$children[0].syncMsg();

        $(document).ready(
            function () {

                $("body").off();

                // window.uname = window.$cookies.get("username");
                // var uname = window.uname;

                // const args = [uname, true, ['bar', 5], {
                //     foo: 'baz'
                // }];
                // try {
                //     console.log("entering user");
                //     window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                //     console.log("passed");

                // } catch (error) {
                //     // alert(error);
                // }

            }
        );


        $(window).resize(function () {

            pointerVue.centerPopupViewFile();

            pointerVue.centerPopupViewFilePdf();

        });


        $(document).ready(function () {

            $('body').on('click', '.selutente', function () {


                pointerVue.sheetUtenti = true;


            });

            $('body').on('click', '.selprogetto', function () {


                pointerVue.sheetProgetti = true;


            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('mouseover', '#myTable img', function () {

                $(this).addClass('transition');

            });



            $('body').on('mouseout', '#myTable img', function () {


                $(this).removeClass('transition');


            });


            $('body').on('click', '.selstato', function () {

                pointerVue.sheetStatus = true;

            });


            $('body').on('click', '.seldata', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });








        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);


        setTimeout(() => {

            this.syncUtenti();

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_change_type":

                    this.sheetTypes = true;

                    break;



                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editSpesa/0"
                    });
                    break;

                case "btn_edit":


                    console.log("ID: ", window.id_par_sel);


                    router.push({
                        path: "/editSpesaDetailsAdmin/" + window.id_par_sel
                    });


                    break;

                case "btn_download":



                    this.downAttach();

                    break;


                case "btn_send_for_appr":

                    this.sendForApprove();

                    break;


                case "btn_set_company":

                    this.sheetAziende = true;

                    break;

                case "btn_set_cdc":

                    this.syncCDC();

                    break;

                case "btn_change_status":

                    this.sheetChangeStatus = true;

                    break;

                case "btn_down_zip":

                    this.downZipAttach();

                    break;

                case "btn_calculator":

                    var list_fatt_sel = "";
                    var tot = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            var v_row = $(this).parent().parent();

                            var item = window.table.row(v_row).data();

                            console.log("ROM SELECTED: ", item);

                            if (list_fatt_sel.length > 0) {
                                list_fatt_sel = list_fatt_sel + "<br>";
                            }
                            list_fatt_sel = list_fatt_sel + "<div style='display:flex;text-align:right;justify-items:right;justify-content:right'><div>(" + item.user_sender + ") " + item.description + "</div> " + item.tipology_icon + "</div>";



                            var val = item.amount_calc;
                            var v_imp = val.toString().replace(".", "");
                            v_imp = v_imp.toString().replace(",", ".");
                            tot = parseFloat(tot) + parseFloat(v_imp);


                        }

                    });


                    $(".contpopupcalculator").show();

                    this.$modal.show('popupCalculator');

                    setTimeout(() => {

                        $("#divFattSel").html(list_fatt_sel);

                        $("#divTotSel").html(this.formatMoney(tot));



                    }, 200);


                    break;


                case "btn_view_attach":

                    var is_pdf = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            is_pdf = $(this).attr("val-is-pdf");
                        }

                    });


                    if (is_pdf == 0) {

                        this.viewAttach();

                    }


                    if (is_pdf == 1) {

                        console.log("ok");

                        this.viewAttachPdf();


                    }

                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);

    },
    data: () => ({

        progetti: [],

        searchProgetti: "",

        sheetProgetti: false,

        sheetAziende: false,

        utenti: [],

        pdfUrl: "",

        dataDa: null,
        showPicker3: false,

        dataA: null,
        showPicker4: false,


        textSearch: "",

        allTypes: [{
            img: 'https://app.emtool.eu/public/_lib/img/hotel_easyer.png',
            title: 'Hotel',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/carburante_easyer.png',
            title: 'Carburante',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/vitto_easyer.png',
            title: 'Vitto',
            "id": 3,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/aereo_easyer.png',
            title: 'Viaggio',
            "id": 4,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/spese/17.png',
            title: 'Noleggio',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 25,
            height: 25,
            sizeAvatar: "25px"

        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/altro_easyer.png',
            title: 'Altro',
            "id": 6,
            icon: "mdi-account-lock-open",
            "color": "red",
            width: 32,
            height: 32,
            sizeAvatar: "32px"

        },

        ],




        allChangeStatus: [

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_invio_easyer.png',
                title: 'Spesa Da Inviare',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Spesa Approvata',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'Spesa Negata',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Spesa Da Approvare',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/euro_easyer.png',
                title: 'Spesa Pagata',
                "id": 5,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

        ],

        allStatus: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red"
            },


            {
                img: 'https://app.emtool.eu/public/_lib/img/in_invio_easyer.png',
                title: 'Spesa Da Inviare',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Spesa Approvata',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'Spesa Negata',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Spesa Da Approvare',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/euro_easyer.png',
                title: 'Spesa Pagata',
                "id": 5,
                icon: "mdi-account-lock-open",
                "color": "red"
            },

        ],



        aziende: [],

        cdc: [],


        sheetCDC: false,


        sheetStatus: false,

        sheetChangeStatus: false,

        sheetUtenti: false,
        search: "",


        searchCDC: "",


        sheetTypes: false,

        showPicker: false,
        profile: {},

        currItemRow: {},

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredProgetti() {
            return _.orderBy(this.progetti.filter(item => {
                if (!this.searchProgetti) return this.progetti;
                return (item.projectname.toLowerCase().includes(this.searchProgetti.toLowerCase()));
            }), 'headline')
        },


        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


        filteredUsers() {
            return _.orderBy(this.utenti.filter(item => {
                if (!this.search) return this.utenti;
                return (item.name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredCDC() {
            return _.orderBy(this.cdc.filter(item => {
                if (!this.searchCDC) return this.cdc;
                return (item.projectname.toLowerCase().includes(this.searchCDC.toLowerCase()));
            }), 'headline')
        },


    },

    methods: {

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.getAziende(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);


                this.aziende = res.data.aziende;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        manageClickProgetti: async function (progetto) {

            console.log("PROJ SEL: ", progetto);

            this.sheetProgetti = false;

            window.selProgetto = progetto.projectid;


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.setAzienda(azienda.companyid);



        },

        viewAttachPdf: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID SEL: ", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.downAttach(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downAttach", res);



                setTimeout(() => {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = res.data.Result;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();

                    }, 200);



                }, 100);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },


        btnCancelViewFilePdf: function () {

            this.$modal.hide("popupViewFilePdf");

        },

        centerPopupViewFilePdf: function () {


            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },


        centerPopupViewFile: function () {

            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFile').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },




        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },

        viewAttach: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID SEL: ", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.downAttach(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downAttach", res);


                $(".contpopupviewfile").show();

                that.$modal.show('popupViewFile');

                setTimeout(() => {

                    $("#iframe-pdf").attr("src", res.data.Result);

                    that.centerPopupViewFile();


                }, 100);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },




        btnCancelViewFileMobile: function () {

            var that = this;

            that.$modal.hide('popupViewFileMobile');

        },



        btnCancelViewFile: function () {

            var that = this;

            that.$modal.hide('popupViewFile');

        },


        btnCancelPeriod: function () {

            this.$modal.hide('popupDateFrom');

        },

        btnConfirmPeriod: function () {

            window.dataDa = this.dataDa;
            window.dataA = this.dataA;

            this.$modal.hide('popupDateFrom');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        btnResetPeriod: function () {

            this.dataDa = null;
            this.dataA = null;
        },

        formatMoney: function (number, decPlaces, decSep, thouSep) {
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                decSep = typeof decSep === "undefined" ? "," : decSep;
            thouSep = typeof thouSep === "undefined" ? "." : thouSep;
            var sign = number < 0 ? "-" : "";
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;

            return sign +
                (j ? i.substr(0, j) + thouSep : "") +
                i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");

        },

        btnCancelCalculator: function () {

            this.$modal.hide('popupCalculator');

        },


        downZipAttach: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID SEL: ", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.downZipAttach(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downZipAttach", res);

                var file_path = res.data.val_url;
                var a = document.createElement('A');
                a.href = file_path;
                a.target = "_blank";
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );





        },



        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        downAttach: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("ID SEL: ", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.downAttach(
                v_token,
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downAttach", res);

                var file_path = res.data.Result;
                var a = document.createElement('A');
                a.href = file_path;
                a.target = "_blank";
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        changeStatus: async function (status_id) {

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            var cnt_not_approve = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    var v_status_id = $(this).attr("val-status-id");

                    if (parseInt(v_status_id) != 2) // spesa non approvata
                    {

                        cnt_not_approve = cnt_not_approve + 1;

                    }

                }

            });


            console.log("V_TOKEN: ", v_token);
            console.log("ID SEL: ", ids);
            console.log("ID STATUS: ", status_id);
            console.log("CNT NOT APPROVE: ", cnt_not_approve);


            var errore = "";

            if (status_id == 5) { // nota spesa pagata

                if (cnt_not_approve > 0) // ci sono delle note spese non approvate
                {

                    errore = "Le spese da pagare devono essere approvate";

                }

            }



            if (errore == "") {

                console.log("ok");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apiapprovazione.changeStatus(
                    v_token,
                    ids,
                    status_id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from changeStatus", res);

                    that.$swal({
                        icon: "success",
                        text: "Stato cambiato correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    setTimeout(() => {

                        that.hideShowButtons(false);

                        window.table.ajax.reload();

                        $(".checkall").prop("checked", false);


                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }












        },

        manageClickUtenti: async function (utente) {

            console.log("AZI SEL: ", utente);

            this.sheetUtenti = false;

            window.selUser = utente.login;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        manageClickCDC: async function (cdc) {

            console.log("CDC SEL: ", cdc);

            this.sheetCDC = false;

            this.changeCDC(cdc.projectid);

        },




        getUrlFolderDocuments: function (nome_file) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/documents/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/public/easyer/";

            }

            return result + nome_file;

        },


        filterStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            var v_filter = 0;

            if (status == "Spesa Da Inviare") {
                v_filter = 1;
            }
            else if (status == "Spesa Approvata") {
                v_filter = 2;
            }
            else if (status == "Spesa Negata") {
                v_filter = 3;
            }
            else if (status == "Spesa Da Approvare") {
                v_filter = 4;
            }
            else if (status == "Spesa Pagata") {
                v_filter = 5;
            }


            window.curStatus = v_filter;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();



        },

        changeType: async function (status) {
            console.log(status);

            this.sheetTypes = false;

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apispese.changeTypeSpese(
                v_token,
                ids,
                status
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeTypeSpese", res);

                that.$swal({
                    icon: "success",
                    text: "Tipo cambiato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    $(".checkall").prop("checked", false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },


        changeCDC: async function (id_cdc) {
            console.log(status);

            this.sheetCDC = false;

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);
            console.log("ID CDC:", id_cdc);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.changeCDC(
                v_token,
                ids,
                id_cdc
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from changeCDC", res);

                that.$swal({
                    icon: "success",
                    text: "CDC impostato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });


                setTimeout(() => {

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                    $(".checkall").prop("checked", false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },





        syncCDC: async function () {

            var id_azienda = "";
            var v_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    id_azienda = $(this).attr("val-company-id");
                    v_id = $(this).attr("data-id");

                }

            });

            console.log("ID AZI:", id_azienda);


            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.getCDC(
                v_token,
                id_azienda,
                v_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCDC", res);

                this.cdc = res.data.cdc;


                setTimeout(() => {

                    this.sheetCDC = true;

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_send_for_appr = 0;
            var tot_send_with_type = 0;
            var is_same_company = 1;
            var old_company_id = -1;
            var tot_send_with_cmp = 0;
            var tot_send_with_cdc = 0;
            var val_attach_exist = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    val_attach_exist = $(this).attr("val-attach-exist");

                    var val_status_id = $(this).attr("val-status-id");

                    if (val_status_id == 1) // stato aperto
                    {

                        tot_send_for_appr = tot_send_for_appr + 1;

                    }

                    var val_type_id = $(this).attr("val-type-id");

                    if (val_type_id != 0) // tipo impostato
                    {

                        tot_send_with_type = tot_send_with_type + 1;

                    }

                    var val_company_id = $(this).attr("val-company-id");

                    if (old_company_id == -1) {

                        old_company_id = val_company_id;

                    }
                    else {

                        if (old_company_id != val_company_id) {

                            is_same_company = 0;

                        }


                    }


                    if (val_company_id != 0) // azienda impostata
                    {

                        tot_send_with_cmp = tot_send_with_cmp + 1;

                    }

                    var val_cdc_id = $(this).attr("val-cdc-id");

                    if (val_cdc_id != 0) // cdc impostato
                    {

                        tot_send_with_cdc = tot_send_with_cdc + 1;

                    }




                }

            });


            console.log("TOT SEL: ", tot);
            console.log("TOT SEL FOR APPR: ", tot_send_for_appr);
            console.log("TOT SEL WITH TYPE: ", tot_send_with_type);
            console.log("IS SAME COMPANY: ", is_same_company);
            console.log("TOT SEL WITH CMP: ", tot_send_with_cmp);
            console.log("TOT SEL WITH CDC: ", tot_send_with_cdc);


            setTimeout(() => {

                var pulsantis = [];



                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/edit_row_easyer2.png"),
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30

                    });

                    if (val_attach_exist == 1) {

                        pulsantis.push({
                            text: "",
                            icon: "mdi-download-outline",
                            link: "/download",
                            id: "btn_download",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/download_easyer2.png",
                            title: "Scarica",
                            width: 26
                        });

                    }



                }

                if (tot > 1) {

                    pulsantis.push({
                        text: "Cambia Tipo",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/down_zip_easyer.png",
                        link: "/info",
                        id: "btn_down_zip",
                        disabled: false,
                        title: "Scarica ZIP Allegati",
                        width: 20
                    });



                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Cambia Tipo",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/cambio_stato_easyer.png",
                        link: "/info",
                        id: "btn_change_type",
                        disabled: false,
                        title: "Cambia Tipo",
                        width: 28
                    });

                    pulsantis.push({
                        text: "Imposta Azienda",
                        icon: "mdi-refresh",
                        image: "https://app.emtool.eu/public/_lib/img/aziende_easyer.png",
                        link: "/info",
                        id: "btn_set_company",
                        disabled: false,
                        title: "Imposta Azienda",
                        width: 24
                    });

                    if (is_same_company == 1) {

                        if (tot == tot_send_with_cmp) {

                            pulsantis.push({
                                text: "Imposta CDC",
                                icon: "mdi-refresh",
                                image: "https://app.emtool.eu/public/_lib/img/icon_cdc_easyer_50.png",
                                link: "/info",
                                id: "btn_set_cdc",
                                disabled: false,
                                title: "Imposta Progetto",
                                width: 22
                            });



                        }



                    }


                    if ((tot == tot_send_with_cmp) && (tot == tot_send_with_cdc)) {


                        pulsantis.push({
                            text: "Cambia Stato",
                            icon: "mdi-refresh",
                            image: "https://app.emtool.eu/public/_lib/img/invia_in_appr_easyer.png",
                            link: "/info",
                            id: "btn_change_status",
                            disabled: false,
                            title: "Cambia Stato",
                            width: 28
                        });


                    }

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete_row_easyer.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 22
                    });




                }


                if (tot > 1) {

                    pulsantis.push({
                        text: "Calculator",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/calculator-invoiceweb.png",
                        link: "/info",
                        id: "btn_calculator",
                        disabled: false,
                        title: "Calcolatrice",
                        width: 18
                    });



                }


                if (tot == 1) {

                    if (val_attach_exist == 1) {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/view-easyer.png"),
                            link: "/info",
                            id: "btn_view_attach",
                            disabled: false,
                            title: "Vedi",
                            width: 30


                        });

                    }





                }






                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var filtri = `<img src='/easyer/img/refresh-easyer.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/easyer/img/filtro-easyer.png' style='border:0!important;' class='selstato' title='Stato' /><img src='/easyer/img/calendar-easyer.png' style='border:0!important;' class='seldata' title='Periodo' /><img src='/easyer/img/users_menu2.png' style='border:0!important;' class='selutente' title='Utente' /><img src='/easyer/img/icon_cdc_easyer_50.png' style='border:0!important;' class='selprogetto' title='Progetto' />`;

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");
                // var v_company = window.$cookies.get("sel_filter_azienda_utenti");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '0.2%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: true,
                        searchable: false,
                        name: '',
                        width: '0.2%',
                    },

                    {
                        data: 'data_trasferta',
                        orderable: true,
                        searchable: false,
                        name: '',
                        width: '2%',
                    },

                
                    {
                        data: 'user_sender',
                        orderable: true,
                        name: 'user_sender',
                        width: '4%'
                    },



                    {
                        data: 'description',
                        orderable: true,
                        name: 'description',
                        width: '4%'
                    },

                    {
                        data: 'fld_name',
                        orderable: true,
                        name: 'fld_name',
                        width: '4%'
                    },



                    {
                        data: 'full_name_prj',
                        orderable: true,
                        name: 'full_name_prj',
                        width: '4%'
                    },

                    {
                        data: 'amount',
                        orderable: true,
                        name: 'amount',
                        width: '2%'
                    },

                    {
                        data: 'tipology',
                        orderable: false,
                        name: 'tipology',
                        width: '0.6%'
                    },

                    {
                        data: 'status',
                        orderable: false,
                        name: 'status',
                        width: '0.6%'
                    },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EasyERGetSpeseAdminApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;
                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            valori.selUser = window.selUser;

                            valori.selProgetto = window.selProgetto;

                            window.valori = valori;
                            return valori;

                        }

                    },


                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        //$('.p_amount').number(true, 2, ',', '.');

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 1000);



                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        $('.p_importo').number(true, 2, ',', '.');

                        var totale = 0;
                        $(".p_importo").each(function () {
                            totale = totale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        $("#v_tot_importo").text(totale.toString().replace(".", ","));
                        $('#v_tot_importo').number(true, 2, ',', '.');


                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);

                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();


                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EasyERGetSpeseAdminApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    // $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Approvazione</div>
                      ` + filtri + `

                </div>
                <div><img src='/easyer/img/cerca-easyer.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.selaggiorna', function () {

                    pointerVue.hideShowButtons(false);

                    window.table.ajax.reload();

                });

                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_approvazione th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });

                $('body').on('click', '.cont_approvazione tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {

                            pointerVue.currItemRow = row_data;

                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });





                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        // {
                        //     text: "Add",
                        //     icon: "mdi-home-circle",
                        //     image: "https://app.emtool.eu/public/_lib/img/xbox-cross-easyer.png",
                        //     // class: "inverted",
                        //     link: "/settings",
                        //     id: "btn_add",
                        //     disabled: false,
                        //     title: "Aggiungi"

                        // },



                    ];

                }


                if (v_enable == true) {

                    pulsantis = [



                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 22

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 22

                        },



                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        sendForApprove: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Manda In Approvazione",
                    html: "Vuoi mandare in approvazione gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_easyer.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_easyer.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apispese.sendForAppr(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from sendForAppr", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati inviati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                $(".checkall").prop("checked", false);

                                that.$root.$children[0].syncMsg();


                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        syncUtenti: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiapprovazione.getUtenti(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtenti", res);

                this.utenti = res.data.utenti;

                this.progetti = res.data.filtro_progetti;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        setAzienda: async function (id_azienda) {

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);
            console.log("ID AZIENDA:", id_azienda);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response = await apiapprovazione.setAzienda(
                v_token,
                ids,
                id_azienda
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setAzienda", res);

                that.$swal({
                    icon: "success",
                    text: "Azienda impostata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.hideShowButtons(false);

                    $(".checkall").prop("checked", false);



                    window.table.ajax.reload();


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:25px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_easyer.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:25px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_easyer.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }


                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apispese.deleteMultiSpese(
                            v_token,
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMultiSpese", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                $(".checkall").prop("checked", false);

                                that.$root.$children[0].syncMsg();

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.contpopupdatefrom .v-label {
    font-size: 10px !important;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('/easyer/font/fontawesome-webfont.woff2') format('woff2'),
        url('/easyer/font/fontawesome-webfont.woff') format('woff'),
        url('/easyer/font/fontawesome-webfont.ttf') format('truetype');
    font-style: normal;
}


.sorting_asc:after {
    content: "\f0de";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting_desc:after {
    content: "\f0dd";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting:after {
    content: "";
    font-family: 'FontAwesome';
}

.itemAzienda {
    margin-left: 15px;
}

.itemCDC {
    margin-left: 15px;
}

.c_title_tipi {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_stati {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_cdc {
    text-align: left !important;
    margin-left: 44px !important;
}

.c_title_change_status {
    text-align: left !important;
    margin-left: 44px !important;
}


.headersheetUtenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetCDC {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.cont_approvazione .selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .seldata {
    max-width: 27px;
    min-height: 27px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .selutente {
    max-width: 25px;
    min-height: 25px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 25px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .selprogetto {
    max-width: 25px;
    min-height: 25px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 25px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.transition {
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -o-transform: scale(2.5);
    transform: scale(2.5);
}


.contstati v-bottom-sheet.v-dialog {

    align-self: flex-end !important;
    margin-top: 79px !important;
}

#approvazioneGrid .closeStatusSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .closeChangeStatusSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .closeCDCSheet {
    position: absolute;
    right: 0;
}



#approvazioneGrid .closeTypesSheet {
    position: absolute;
    right: 0;
}

#approvazioneGrid .v-application .text-center {

    overflow: auto;
}

#approvazioneGrid .v-list-item {
    margin-left: 20px;
}

#approvazioneGrid .v-list-item__title {
    font-size: 12px;
}

#approvazioneGrid .v-list-item {
    min-height: 42px;
}

#approvazioneGrid .v-list-item__icon {
    margin: 10px 0px;
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.cont_approvazione #myTable {
    margin-right: 3px;
    width: 900px !important;
}

.cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_approvazione th {
    padding-left: 5px !important;
}

.cont_approvazione ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_approvazione .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_approvazione #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #00246a;

}

.cont_approvazione div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_approvazione #myTable_wrapper {
    min-width: initial;
    min-width: 1000px;
    max-width: 1000px;
    width: 1000px;
}


.cont_approvazione .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_approvazione .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}


.cont_approvazione .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_approvazione .checkall {
    width: 13px;
    height: 13px;
}

.cont_approvazione .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_approvazione .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 50px;
    padding-left: 10px;
    justify-content: inherit;

}


.cont_approvazione .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 64px !important;
    padding-left: 0px !important;
    height: 10px;
}

.cl-time {
    margin-top: -15px;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_approvazione .cl_importo {
    text-align: right;
}

.cont_approvazione .cl_tipology {
    text-align: center;
    padding-left: 5px;
}

.cont_approvazione .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_approvazione .val_status {
    width: 10px !important;
}

.cont_approvazione .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_approvazione .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_approvazione .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_approvazione .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

#content_attachment {
    width: 50px !important;
    /* margin: 25px !important; */
    margin-right: 25px !important;
    margin-left: 25px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

}

#content_attachment {
    -webkit-transition: all .4s ease-in-out !important;
    -moz-transition: all .4s ease-in-out !important;
    -o-transition: all .4s ease-in-out !important;
    -ms-transition: all .4s ease-in-out !important;
}


.swal2-icon.swal2-warning {
    border-color: #00246a;
    color: #00246a;
}


@media screen and (max-width: 960px) {


    .cont_approvazione .cl_cdc {
        display: inline;
    }

    .cont_approvazione .val_status {
        margin-top: -7px !important
    }

    .cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
    .cont_approvazione table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
        padding-left: 36px !important;
    }

    .cont_approvazione .cl_checkall {
        padding-left: 30px;
        padding-top: 2px;
    }


    .cont_approvazione .font-row-grid-datetime-mobile2 {
        font-size: 10px !important;
        padding: 5px !important;
        width: 82px !important;
        padding-left: 19px !important;
        height: 10px;
        display: inline-block;
    }


    .cont_approvazione .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_approvazione #myTable {
        /* margin-top:54px; */
    }

    .cont_approvazione #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_approvazione .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_approvazione #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_approvazione #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_approvazione .cl_num {
        margin-left: -3px !important;
    }


}
</style>
