<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center editProfile"
        style="min-width:280px;">

        <v-form style="min-width:300px;" ref="form" v-model="valid" lazy-validation>

            <v-avatar color="primary" size="56">
                <span class="white--text text-h5">{{ initials }}</span>

            </v-avatar>
            <v-row class="text-center titlenewrow" style="">

                <span>{{ namesurname }}</span>
            </v-row>


            <v-text-field v-model="profile.name" label="Nome" required :rules="genRules"></v-text-field>

            <v-text-field v-model="profile.surname" label="Cognome" required :rules="genRules"></v-text-field>

            <v-text-field v-model="profile.mail" :rules="emailRules" label="E-mail" required></v-text-field>


            <!-- <v-text-field v-model="profile.username" label="User" readonly></v-text-field>

        <v-text-field v-model="profile.mail" :rules="emailRules" label="E-mail" required></v-text-field>

        <v-text-field v-model="profile.tel_cell" :rules="cellulareRules" label="Phone number" required></v-text-field> -->



            <!-- <v-text-field v-model="profile.name" label="Name"  ></v-text-field>

        <v-text-field v-model="profile.surname" label="Surname"  ></v-text-field>

        <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="profile.data_nascita" label="Birth date" hint="YYYY/MM/DD" persistent-hint readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="profile.data_nascita" no-title @input="showPicker = false"></v-date-picker>
        </v-menu>


        <v-text-field v-model="profile.codice_fiscale" :rules="codicefiscaleRules" label="Codice fiscale" :counter="16" required></v-text-field>

        <v-text-field v-model="profile.iban" :counter="30" :rules="ibanRules" label="Iban" required></v-text-field>

        <v-row class="text-center titlenewrow" style=" ">
            Residenza
        </v-row>

        <v-text-field v-model="profile.indirizzo" :counter="30" :rules="genRules" label="indirizzo" required></v-text-field>

        <v-text-field v-model="profile.citta_res" :rules="genRules" label="Citta" required></v-text-field>

        <v-text-field v-model="profile.cap_res" :rules="genRules" label="CAP" required></v-text-field>

        <v-text-field v-model="profile.provincia_res" :rules="genRules" label="Provincia" required></v-text-field>

        <v-row class="text-center titlenewrow" style=" ">
            Domicilio
        </v-row>

        <v-text-field v-model="profile.indirizzo_dom" :counter="30" label="indirizzo" required></v-text-field>

        <v-text-field v-model="profile.citta_dom" label="Citta" required></v-text-field>

        <v-text-field v-model="profile.cap_dom" label="CAP" required></v-text-field>

        <v-text-field v-model="profile.provincia_dom" label="Provincia" required></v-text-field> -->

            <!--   <v-text-field
            v-model="profile.data_nascita"
            label="Data nascita"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field> -->

            <!--  <v-select
            v-model="select"
            :items="items"
            :rules="[(v) => !!v || 'Item is required']"
            label="Item"
            required
          ></v-select>

          <v-checkbox
            v-model="checkbox"
            :rules="[(v) => !!v || 'You must agree to continue!']"
            label="Do you agree?"
            required
          ></v-checkbox> -->

            <!--   <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Save
          </v-btn>

          <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn> -->

            <!--     <v-btn
      color="warning"
      @click="resetValidation"
    >
      Reset Validation
    </v-btn> -->

            <div class="padding" style="min-height:100px;"></div>
        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>
                            </v-toolbar>
                        </div>
                        <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                        close
                    </v-btn> -->
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti
                            <!-- <v-switch @change="changeGruppi" style="position: absolute;z-index: 1;left: 8px;top: -13px;" v-model="switch1" :label="` `"></v-switch> -->

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
import _ from 'lodash';

export default {

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {



        /*     let self = this;
            fetch('https://jsonplaceholder.typicode.com/posts')
              .then(response => response.json())
              .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        this.aziende = this.$root.$children[0].aziende;
        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        this.setupButtons();
        setTimeout(() => {
            this.syncProfile();

        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_aziende":
                    //this.admindialog = true ;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);
                    break;

                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({

        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        changeDip: function () {

            /*   var token = window.$cookies.get("token");
              var system = window.$cookies.get("system");
              var user_id = window.$cookies.get("user_id");

              if (this.currDipendenteObj.resourceid !== undefined) {
                  //alert (  this.currDipendenteObj.resourceid  );
                  system = this.currDipendenteObj.v_res_id;
                  token = this.currDipendenteObj.v_user;
                  user_id = this.currDipendenteObj.v_user_id;

              } */

            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;

                }
            }

            this.$root.$children[0].curDipTop = selNameDip;

            // console.log(token, system, user_id);
            this.syncProfile();

        },
        manageClickDipendenti: function (dipendente) {
            this.sheetDipendenti = false;
            this.currDipendente = dipendente.resourceid;
            //  alert ( dipendente );
            // this.resetDay();
            this.changeDip();
            setTimeout(() => {
                // this.syncMonth();

            }, 100);
            /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {
                this.sheetAziende = false;
                this.sheetDipendenti = true;
            }, 300);

        },

        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },

        setupButtons: async function () {

            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [{
                    text: "Save",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_add_tms",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
                    title: "Salva",
                    width: 30

                },


                ];

                // var a = window.$cookies.get("a");
                // if (a == 'Y' || a == 'S') {
                //     pulsantis.push({
                //         text: "",
                //         icon: "mdi-domain",
                //         link: "/dash",
                //         id: "btn_aziende",
                //         disabled: false,
                //         image: "https://app.emtool.eu/public/_lib/img/aziende.png",
                //         class: "inverted",
                //         title: "Aziende",
                //         width: 22


                //     });
                // }

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                //   this.$root.$children[0].$refs.btn_add_tms;

                // console.log( "ok" ,  this.$root.$refs.mainApp );
                //this.$root.$refs.mainApp.bnavbuttons = pulsantis;

                //   refs.btn_add_tms
                // console.log ("Refs" , this.$root.$children[0].$refs.btn_add_tms );

                // var refs = this.$root.$children[0].$refs.btn_add_tms ;

                //   var add_tms = refs.btn_add_tms ;

                //d console.log(this.$root.$children[0].$refs.btn_add_tms);
                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        /*       setupButtons: async function () {
        setTimeout(() => {
            var pulsantis = [{
                text: "Dashboard",
                icon: "mdi-home-circle",
                link: "/dash",
                id: "btn_exit",
                disabled: false,
            }, ];
            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-message-plus",
                    link: "/dash",
                    id: "btn_aziende",
                    disabled: false,
                }, );
            }
            this.$root.$children[0].bnavbuttons = pulsantis;
        }, 100);
    },
*/

        saveProfile: async function () {

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");


            if (this.currDipendenteObj.resourceid !== undefined) {
                username = atob(this.currDipendenteObj.v_user);
                token_system = this.currDipendenteObj.way;

            }

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {

                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                /*     this.profile = this.iban = response.data.Result;
                       //console.log("HERE",response.data.Result.iban);
                    this.iban = response.data.Result.iban ;
                    this.email = response.data.Result.mail ;
                    this.codicefiscale = response.data.Result.codice_fiscale ;
                    this.cellulare = response.data.Result.tel_cell ;
                    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
                    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;

            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
             var system = window.$cookies.get("system"); */

            setTimeout(async () => {

                var username = window.$cookies.get("username");
                var token_system = window.$cookies.get("token_system");

                console.log("oki", this.currDipendenteObj);

                if (this.currDipendenteObj.resourceid !== undefined) {
                    //alert (  this.currDipendenteObj.resourceid  );
                    token_system = this.currDipendenteObj.way;
                    username = atob(this.currDipendenteObj.v_user);

                }

                //   console.log ( system );
                this.showSpinner = true;
                this.$root.$children[0].showProgress = true;

                var result;
                try {
                    // alert ( username + " +" + token_system );
                    result = await apiprofile.getProfile(
                        username,
                        token_system
                    ).then((response) => {
                        // alert ("finshed");

                        // console.log (response);
                        this.showSpinner = false;
                        this.$root.$children[0].showProgress = false;

                        try {

                            this.profile = response.data.Result;
                            this.profile.username = username;
                            //console.log("HERE",response.data.Result.iban);
                            this.iban = response.data.Result.iban;
                            this.email = response.data.Result.mail;
                            this.codicefiscale = response.data.Result.codice_fiscale;
                            this.cellulare = response.data.Result.tel_cell;
                            this.namesurname = response.data.Result.name + " " + response.data.Result.surname;
                            this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0);

                            //this.monthArray = response.data.Timesheet;

                        } catch (error) {
                            console.log(error);
                            alert(error.message);
                        }

                        console.log(result);

                    });
                } catch (error) {
                    alert(error.message);

                    this.showSpinner = false;
                    this.$root.$children[0].showProgress = false;
                }

            }, 300);

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
  console.log('a phone number was clicked');
}    <template>
    <div class="text-center">
        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
            <v-sheet class="text-center" height="100vh">
                <div class="headerSheetAziende" fixed style="position:fixed;">
                    Aziende
                    <v-btn class='closeSheet' icon @click="manageClose()">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <v-toolbar>
                        <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>
                    </v-toolbar>
                </div>
                <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                    close
                </v-btn> -->
                <v-divider></v-divider>
                <div class="spacer" style="min-height:72px;"></div>
                <v-flex xs12 sm12>
                    <v-card>

                        <v-list-item class="text-left" v-for="(tile,key) in filteredAziende" :key="key" @click="manageClickAziende(tile)">
                            <v-icon aria-hidden="false">mdi-domain</v-icon>
                            <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                            <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                        </v-list-item>
                    </v-card>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

,
*/
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {

                console.log("Profile: ", this.profile);
                this.saveProfile();

            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;

}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.editProfile .v-input {
    font-size: 1.2em !important;
}

.editProfile .v-select {
    font-size: 1.2em !important;
}

.editProfile .v-label {
    font-size: 1em !important;
}
</style>
