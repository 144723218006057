<template>
    <v-container class="w-container-users-easyer v-overflow editUtenteEasyER"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                background-color: white!important;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#00246a;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>

            <div class="tabs">
                <v-tabs v-model="tab" centered next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline" show-arrows style="max-width:100vw;font-size:10px;">

                    <!-- s -->
                    <v-tabs-slider color="#00246a"></v-tabs-slider>
                    <v-tab v-for="item in items" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">

                    <v-tab-item key="Informazioni">

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.nome_utente" label="Nome" required :rules="nameRules">
                                </v-text-field>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.cognome_utente" label="Cognome" required
                                    :rules="surnameRules">
                                </v-text-field>

                            </v-col>

                        </v-row>





                        <v-row>


                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.login" label="Username" required :rules="usernameRules">
                                </v-text-field>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.codice_fiscale" label="Codice Fiscale"></v-text-field>

                            </v-col>


                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <div id="cont_companyid" style="cursor:pointer">
                                    <ion-item id="itemAzienda"
                                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                            position="floating">Azienda </ion-label>

                                        <ion-select id="companyid" name="companyid" class='companyid'
                                            cancel-Text="Chiudi" done-Text="" :value="utente.idcompany_logo"
                                            interface="action-sheet" disabled readonly>

                                            <ion-select-option v-for="item in aziende" :key="item.companyid"
                                                :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                        </ion-select>
                                    </ion-item>
                                </div>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.email" label="E-Mail" required :rules="emailRules">
                                </v-text-field>

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                                <v-select v-model="utente.prefix_mobile" :items="prefissi" label="Prefisso"
                                    item-text="prefix" item-value="prefix"></v-select>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-text-field v-model="utente.mobile" label="Telefono"></v-text-field>

                            </v-col>

                        </v-row>


                        <v-row>

                            <v-col v-if="changePsw" cols="12" md="6" xs="6">

                                <div style="margin-left: 10px;">Cambio Password</div>

                                <div style="margin-left: 10px;margin-top:22px">

                                    <router-link :to="'/changepasswordadmin/' + this.$route.params.id">
                                        <img style="max-width:24px;"
                                            src="https://app.emtool.eu/_lib/img/usr__NM__bg__NM__new_mnu_change_password.png" />
                                    </router-link>


                                </div>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                        </v-row>

                    </v-tab-item>

                    <v-tab-item key="Gruppo">

                        <v-row>

                            <v-col cols="12" md="12" xs="12">

                                <v-list style="max-height: 600px" class="overflow-y-auto">

                                    <v-list-tile v-for="(gruppo, index) in gruppi" :key="gruppo.id">

                                        <v-list-tile-content>
                                            <v-checkbox :value="gruppo.id" :key="gruppo.id" :label="gruppo.name"
                                                v-model="gruppi[index].checked" class="cl-checkbox">
                                            </v-checkbox>
                                        </v-list-tile-content>

                                    </v-list-tile>

                                </v-list>

                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                            </v-col>

                        </v-row>

                    </v-tab-item>



                    <v-tab-item key="EasyER">

                        <v-row>

                            <v-col cols="12" md="12" xs="12">

                                <v-checkbox label="Abilitato" v-model="utente.enable_easyer" true-value="S"
                                    false-value="N" class="chkEnableEasyER cl-checkbox"
                                    id="chkEnableEasyER"></v-checkbox>

                            </v-col>

                        </v-row>

                        <v-row v-if="utente.enable_easyer == 'S'">

                            <v-col cols="12" md="12" xs="12">

                                <div id="d_importo_user"
                                    class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                        <div class="v-input__slot">
                                            <div class="v-text-field__slot"><label for="input-29"
                                                    class="v-label v-label--active theme--light"
                                                    style="left: 0px; right: auto; position: absolute">Massimale</label>

                                                <vue-autonumeric id="valMax" v-model="utente.max_easyer"
                                                    :disabled="true" :options="{
                                                        digitGroupSeparator: '.',
                                                        decimalCharacter: ',',
                                                        decimalCharacterAlternative: '.',
                                                        currencySymbol: '\u00a0€',
                                                        currencySymbolPlacement: 's',
                                                        roundingMethod: 'U',
                                                        minimumValue: '0'
                                                    }"></vue-autonumeric>

                                            </div>
                                        </div>

                                        <div class="v-text-field__details">
                                            <div class="v-messages theme--light">
                                                <div class="v-messages__wrapper"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </v-col>

                        </v-row>

                        <v-row v-if="utente.enable_easyer == 'S'">

                            <v-col cols="12" md="6" xs="6">

                                <v-menu v-model="showPicker" :close-on-content-click="false"
                                    transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field id="periodoDal" v-model="utente.datestart_easyer"
                                            label="Dal Periodo" persistent-hint readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="utente.datestart_easyer" no-title
                                        @input="showPicker = false" locale="it"></v-date-picker>
                                </v-menu>


                            </v-col>

                            <v-col cols="12" md="6" xs="6">

                                <v-menu v-model="showPicker2" :close-on-content-click="false"
                                    transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field id="periodoAl" v-model="utente.dateend_easyer" label="Al Periodo"
                                            persistent-hint readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="utente.dateend_easyer" no-title @input="showPicker2 = false"
                                        locale="it"></v-date-picker>
                                </v-menu>

                            </v-col>

                        </v-row>


                        <v-row v-if="utente.enable_easyer == 'S'">

                            <v-col cols="12" md="12" xs="12">

                                <div id="d_euro_km_user"
                                    class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                    <div class="v-input__control">
                                        <div class="v-input__slot">
                                            <div class="v-text-field__slot"><label for="input-29"
                                                    class="v-label v-label--active theme--light"
                                                    style="left: 0px; right: auto; position: absolute">€ / Km</label>

                                                <vue-autonumeric id="valMax" v-model="utente.euro_km" :disabled="true"
                                                    :options="{
                                                        digitGroupSeparator: '.',
                                                        decimalCharacter: ',',
                                                        decimalCharacterAlternative: '.',
                                                        currencySymbol: '',
                                                        currencySymbolPlacement: 's',
                                                        roundingMethod: 'U',
                                                        minimumValue: '0'
                                                    }"></vue-autonumeric>

                                            </div>
                                        </div>

                                        <div class="v-text-field__details">
                                            <div class="v-messages theme--light">
                                                <div class="v-messages__wrapper"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </v-col>

                        </v-row>




                    </v-tab-item>



                </v-tabs-items>

            </div>

        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Massimale</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreMassimale" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldMassimale" id="fldMassimale"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-massimale" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-massimale" contain
                                    src="@/assets/btn_confirm3_easyer.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupeurokm">

            <template>
                <modal name="popupEuroKm" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">€ / Km</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreEuroKm" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldMassimale" id="fldMassimale"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-euro-km" contain src="@/assets/btn_cancel3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-euro-km" contain src="@/assets/btn_confirm3_easyer.png"
                                    max-width="30" max-height="30" style="cursor:pointer"
                                    transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        //window.$cookies.get("aziende_user")

        this.setupButtons();

        if (this.$route.params.id == 0) {

            setTimeout(() => {

                this.syncAziende();

            }, 100);

        }

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncUtente();
            }, 100);

            this.changePsw = true;

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Utente";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/users"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });

            $('body').on('click', '#d_euro_km_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupEuroKm');

            });

            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                console.log("VALORE: ", pointerVue.valoreMassimale);

                setTimeout(() => {

                    pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                    pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                    pointerVue.$modal.hide('popupImporto');

                }, 200);



            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-euro-km', function (event) {
                console.log(event);

                console.log("VALORE: ", pointerVue.valoreEuroKm);

                setTimeout(() => {

                    pointerVue.utente.euro_km = pointerVue.valoreEuroKm;

                    pointerVue.$root.$children[0].setFieldActive("#d_euro_km_user");

                    pointerVue.$modal.hide('popupEuroKm');

                }, 200);


            });

            $('body').on('click', '.btn-cancel-euro-km', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupEuroKm');

            });








        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        showPicker2: false,

        valoreMassimale: null,
        valoreEuroKm: null,

        changePsw: false,

        titolo: "Aggiungi Utente",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        // btnCancelEuroKm: function () {

        //     this.$modal.hide('popupImporto');

        // },

        // btnConfirmEuroKm: function () {

        //     this.utente.euro_km = this.valoreEuroKm;

        //     this.$root.$children[0].setFieldActive("#d_euro_km_user");

        //     this.$modal.hide('popupEuroKm');

        // },







        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
                    title: "Salva",
                    width: 30

                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/parenti",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/back-easyer.png",
                    width: 30

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("UTENTE 1: ", that.utente);

            //console.log("COMPANYID: ", $("#companyid").val());

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                var errore = "";

                //console.log("GRUPPI: ", that.gruppi);

                if ($("#companyid").val() == "") {
                    errore = "Specificare l'azienda";
                }



                if (that.utente.is_su != "S") {

                    if (errore == "") {

                        var grpChecked = 0;

                        for (var i = 0; i < that.gruppi.length; i++) {

                            if ((that.gruppi[i] !== undefined) && (that.gruppi[i].checked !== undefined)) {

                                if (that.gruppi[i].checked != null) {
                                    grpChecked = grpChecked + 1;
                                }

                            }

                        }

                        console.log("GTP CHCK: ", grpChecked);

                        if (grpChecked == 0) {
                            errore = "Per favore specificare i gruppi";
                        }
                    }

                }





                if (errore == "") {

                    if ($("#chkEnableEasyER").is(":checked")) {

                        console.log("VAL_MAX: ", $("#valMax").val());

                        if ($("#valMax").val() == "") {

                            errore = "Specificare massimale nota spese";

                        }

                    }

                }

                if (errore == "") {

                    if ($("#chkEnableEasyER").is(":checked")) {


                        if ($("#periodoDal").val() == "") {

                            errore = "Specificare dal periodo nota spese";

                        }


                    }

                }

                if (errore == "") {

                    if ($("#chkEnableEasyER").is(":checked")) {

                        if ($("#periodoAl").val() == "") {

                            errore = "Specificare al periodo nota spese";

                        }


                    }

                }


                if (errore == "") {

                    if ($("#chkEnableEasyER").is(":checked")) {

                        if (Date.parse($("#periodoDal").val()) >= Date.parse($("#periodoAl").val())) {

                            errore = "Periodo specificato nella nota spese non valido";

                        }

                    }

                }




                if (errore == "") {

                    that.utente.idcompany_logo = $("#companyid").val();

                    that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }



            }
        },

        saveData: async function () {

            var that = this;

            console.log("UTENTE: ", that.utente);

            var v_token = window.$cookies.get("token");

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.insertUtente(
                    that.utente,
                    that.gruppi,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.updateUtente(
                    that.utente,
                    that.gruppi,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateUtente", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/users"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token,
                "easyer"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;

                        this.utente.euro_km = res.data.info_easyer.euro_km;

                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token,
                "easyer"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users-easyer {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtenteEasyER .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtenteEasyER .v-input {
    font-size: 1.2em !important;
}

.editUtenteEasyER .v-select {
    font-size: 1.2em !important;
}

.editUtenteEasyER .v-label {
    font-size: 1em !important;
}

.editUtenteEasyER .cl-checkbox i {
    font-size: 13px !important;
}


.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .w-container-users-easyer {
        width: 100%;
        padding: 0px;
    }


}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
