<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">

        <v-col v-if="viewSpese == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/spese">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/spese_50.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Spese</div>
                </template>
                <span>Spese</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewApprovazione == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/approvazione">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/amministrazione.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Approvazione</div>
                </template>
                <span>Approvazione</span>
            </v-tooltip>

        </v-col>


    </v-container>
</template>


<script>



import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        if (this.isSU == 0) {

            this.viewSpese = this.getEnableConf("spese_easyer");
            this.viewApprovazione = this.getEnableConf("appr_easyer");

        }
        else {

            this.viewSpese = 1;
            this.viewApprovazione = 1;


        }

        this.loadLogo();


        this.getLastLogin();



    },

    methods: {

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "easyer")
                    .then((res) => {

                        console.log("res LoadLogoCompanyNew: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;




                    });
            } catch (error) {
                console.log("err LoadLogoCompanyNew", error);
            }

            console.log(response);



        },

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "easyer"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },





    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        viewSpese: 0,
        isAdmin: 0,
        isSU: 0,
        viewApprovazione: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
