<template>
  <div class="carsettings">
    <v-carousel
      v-model="currentIndex"
      class="mainCarousel"
      height="100vh"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item class="itemCar" v-for="(slide, i) in items" :key="i">
        <div
          class="divImageOk"
          :id="'imageset' + i"
          :style="{ 'background-image': 'url(' + slide.src + ')' }"
        ></div>

        <input class="clFiles" type="file" :id="'file' + i" />
      </v-carousel-item>
    </v-carousel>

    <v-bottom-navigation
      class="bottomReturn"
      v-model="value"
      background-color="white"
      dark
      shift
    >
      <router-link class="linkBottom" tag="a" :to="'/'"> LOGIN</router-link>
    </v-bottom-navigation>
  </div>
</template>

<script>
import apidoor from "../utils/door/apidoor";
import { bus } from "../main";
import router from ".././router";

export default {
  data() {
    return {
      currentIndex: 0,
      items: [],
      itemsOld: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
 
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  destroyed: function () {
    this.$root.$children[0].bnavbuttons = [];
  },
  mounted: function () {
    /*eslint-disable no-undef*/
    this.initSlide();
    this.setupButtonsGrid();

    var that = this;

    $(document).ready(function () {
      $("body").off();

      $("body").on("change", ".clFiles", function (event) {
        console.log(event);
        that.upload(event.target.id);
      });
    });

    bus.$on("bottomIconClickEvent", (data) => {
      //this.header = data;
      console.log(data);
      switch (data) {
        case "btn_add":
          this.manageAdd();
          break;

        case "btn_delete":
          this.manageDel();
          break;

           
        case "btn_edit":
          this.edit();

          break;

        case "btn_exit":
          router.push({
            path: "/dash",
          });
          break;

        default:
          break;
      }
    });
  },
  methods: {
    upload: function (idfile) {
      var endpointUpload =
        this.getUrlDomainSimple("api/opendoor/") +
        "EasyOnOffManageBackgroundUploadSlideApi";
      var url = endpointUpload;
      console.log(url);
      var fd = new FormData();
      var files = $("#" + idfile)[0].files[0];
      console.log("FILE: ", files);

      var that = this;
      var username = window.$cookies.get("username");



      fd.append("app", "easyer_settings");
      fd.append("logo", files);
      fd.append("username", username);
      fd.append("index", window.last);

      $.ajax({
        url: url,
        container: "#formSettings",
        data: fd ,
        type: "post",
        contentType: false,
        processData: false,
        success: function (data) {
          console.log(data);

          setTimeout(function () {
            that.initSlide();
          }, 50);
        },
      });
    },

    getUrlDomainSimple: function (nome_metodo) {
      let result = "";

      if ($(location).attr("hostname") == "localhost") {
        result = $(location).attr("protocol") + "//emtool.local/";
      }

      if ($(location).attr("hostname") != "localhost") {
        result =
          $(location).attr("protocol") +
          "//" +
          $(location).attr("hostname") +
          "/";
      }

      return result + "" + nome_metodo;
    },

    manageDel: function () {


      var endpointDel =
        this.getUrlDomainSimple("api/opendoor/") +
        "EasyOnOffManageBackgroundUploadSlideApiDelete";
      var url = endpointDel;
      console.log(url);
      var fd = new FormData();
      var that = this;
      var username = window.$cookies.get("username");

   
      fd.append("username", username);
      fd.append("index", this.currentIndex);
      fd.append("app", "easyer_settings");


      $.ajax({
        url: url,
        container: "#formSettings",
        data: fd ,
        type: "post",
        contentType: false,
        processData: false,
        success: function (data) {
          console.log(data);

          setTimeout(function () {
            that.initSlide();
          }, 50);
        },
      });


 
    },

    manageAdd: function () {
      this.items.push({ src: "" });

      var index = this.items.length - 1;

      if (index == -1) index = 0;

    

      this.currentIndex = index;

      setTimeout(function () {
        $("#file" + index).trigger("click");
      }, 300);

      window.last = index;
    },

    initSlide: async function () {
      //Start

      var appdir = "easyer";
      var apptable = "easyer_settings";
      var prePath = this.getUrlDomainSimple("public/"  +  appdir  + "/slides/");
      var username = window.$cookies.get("username");
      var v_token = window.$cookies.get("token");
      var that = this;
      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apidoor
        .getSlides(username, v_token, apptable)
        .then((res) => {
          that.$root.$children[0].showProgress = false;
          console.log("res from initslides get", res.data);
          var arr = res.data.data;

          var newItems = [];

          try {
            arr.forEach(function (path) {
              console.log("ciao", path);

              newItems.push({ src: prePath + path });
            });

            that.items = newItems;
          } catch (error) {
            console.log(error);
          }

           
        })
        .catch((err) => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000,
          });
          console.log("ERrori", "Non è stato possibile effettuare la chiamata");
          console.log("response", response);
        });
      //ENd
    },

    edit: function () {
      alert(this.currentIndex);
    },

    setupButtonsGrid: async function () {
      setTimeout(() => {
        var pulsantis = [
          {
            text: "Add",
            icon: "mdi-home-circle",
            image: "https://app.easyonoff.eu/public/_lib/img/add_esyonoff.png",
            class: "buttons",
            link: "/settings",
            id: "btn_add",
            disabled: false,
          },
          {
            text: "Delete",
            icon: "mdi-home-circle",
            image:
              "https://app.easyonoff.eu/public/_lib/img/delete_easyonoff.png",
            class: "buttons",
            link: "/info",
            id: "btn_delete",
            disabled: false,
          },
         /*  {
            text: "Dashboard",
            icon: "mdi-home-circle",
            image:
              "https://app.easyonoff.eu/public/_lib/img/edit_easyonoff.png",
            class: "buttons",
            link: "/dash",
            id: "btn_edit",
            disabled: false,
          }, */
        ];
        this.$root.$children[0].bnavbuttons = pulsantis;
        setTimeout(() => {}, 100);
      }, 100);
    },
  },
};
</script>

<style>
 
.carsettings .mainCarousel .v-carousel__controls {
  bottom: 100px;
}

.carsettings .mainCarousel .theme--dark.v-btn.v-btn--icon {
  color: #000000;
}

.carsettings .linkBottom {
  font-weight: 600;
  text-decoration: none;

  bottom: 16px;
  text-align: right;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5) !important;
  align-items: center;
}

.carsettings .fullScreen {
  min-height: 100vh;
}

.carsettings .divImageOk {
  min-height: 88vh;
  min-width: 100vw;

  max-width: 100px;

  background-size: contain !important;
  background-repeat: no-repeat;

  max-width: 100px;
  background-position: center;
}

 

.buttons {
  max-height: 28px;
}
</style>