<template>
    <v-container class="w-container-edit-group v-overflow edit_gruppo" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#00246a">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;color:black!important;cursor:pointer;opacity:1"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="gruppo.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="name" v-model="gruppo.name" label="Nome"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_departmentid" style="cursor:pointer">
                        <ion-item id="itemDipartimento"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;color:black!important;cursor:pointer;opacity:1"
                                position="floating">Dipartimento </ion-label>

                            <ion-select id="departmentid" name="departmentid" class='departmentid' cancel-Text="Chiudi"
                                done-Text="" :value="gruppo.departmentid" interface="action-sheet">

                                <ion-select-option v-for="item in dipartimenti" :key="item.departmentid"
                                    :value='item.departmentid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>



            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_scopeid" style="cursor:pointer">
                        <ion-item id="itemScope"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;color:black!important;cursor:pointer;opacity:1"
                                position="floating">Scope </ion-label>

                            <ion-select id="group_scope_id" name="group_scope_id" class='group_scope_id'
                                cancel-Text="Chiudi" done-Text="" :value="gruppo.group_scope_id"
                                interface="action-sheet">

                                <ion-select-option v-for="item in scopes" :key="item.id" :value='item.id'> {{ item.name
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>




        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_easyer.png" max-width="24" max-height="24" style="margin-right:10px" />
                                    
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-easyer.png" max-width="24" max-height="24" />
                                        
                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.setupButtons(this.$route.params.id);

        if (this.$route.params.id == 0) {

            this.initInsertGruppo();

        }


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Gruppo";

            this.initEditGruppo();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/groups"
                    });
                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        gruppo: {},

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        currAzienda: "",
        titolo: "Aggiungi Gruppo",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    methods: {

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },

        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.gruppo.companyid = azienda.companyid;

            this.setDipartimenti(azienda.companyid);

            this.setScopes(azienda.companyid);


        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function (v_id) {

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
                    title: "Salva",
                    width: 30
                });

                if (v_id > 0) {

                    pulsantis.push({
                        text: "Utenti",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_utenti",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/users_easyer.png",
                        title: "Utenti",
                        width: 30

                    });

                    pulsantis.push({
                        text: "Mail",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_mail",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/btn_mail_easyer.png",
                        title: "Mail",
                        width: 30

                    });

                }


                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-easyer.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },

        validate() {

            var that = this;

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#name").val() == "") {
                    errore = "Specificare il nome";
                }

            }

            if (errore == "") {

                if ($("#departmentid").val() == "") {
                    errore = "Specificare il dipartimento";
                }

            }


            if ($("#group_scope_id").val() != "") {

                that.gruppo.group_scope_id = $("#group_scope_id").val();

            }


            if (errore == "") {


                that.saveData();

            }

            if (errore != "") {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token,
                "easyer"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        


        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initEdit(
                that.$route.params.id,
                v_token,
                "easyer"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEditGruppo", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                console.log("COMPANYID: ", res.data.gruppo.companyid);

                this.scopesTemp = res.data.scope;

                this.setDipartimenti(res.data.gruppo.companyid);

                this.setScopes(res.data.gruppo.companyid);

                this.gruppo = res.data.gruppo;


                if (res.data.gruppo.companyid != null) {

                    $("#itemCompany").addClass("item-has-value");

                }

                if (res.data.gruppo.departmentid != null) {

                    $("#itemDipartimento").addClass("item-has-value");

                }

                if (res.data.gruppo.group_scope_id != null) {

                    $("#itemScope").addClass("item-has-value");

                }


                var is_load_users_from_group = window.$cookies.get("is_load_users_from_group");

                if (is_load_users_from_group == 1) {

                    if (res.data.recipients != null) {

                        window.$cookies.set("sel_id_users", res.data.recipients, "9y");

                    }

                    if (res.data.mails != null) {

                        window.$cookies.set("sel_mails", res.data.mails, "9y");

                    }

                    window.$cookies.set("is_load_users_from_group", 0, "9y");
                }




                setTimeout(() => {
                    
                    window.$cookies.set("nome_gruppo_edit", that.gruppo.name, "9y");

                    var v_scope_name = "";

                    for (var x = 0; x <= this.scopesTemp.length - 1; x++)
                    {

                        if (parseInt(this.scopesTemp[x].id) == that.gruppo.group_scope_id)
                        {

                            v_scope_name = this.scopesTemp[x].name;

                        }

                    }

                    window.$cookies.set("nome_scope_gruppo_edit", v_scope_name, "9y");

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            if ($("#companyid").val() != "") {
                that.gruppo.companyid = $("#companyid").val();
            }

            that.gruppo.name = $("#name").val();

            if ($("#departmentid").val() != "") {
                that.gruppo.departmentid = $("#departmentid").val();
            }

            if ($("#group_scope_id").val() != "") {
                that.gruppo.group_scope_id = $("#group_scope_id").val();
            }

            var response = await apigroups.saveGroup(
                that.gruppo,
                v_token,
                id_rec,
                window.$cookies.get("sel_id_users"),
                window.$cookies.get("sel_mails")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveGroup", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/groups"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container-edit-group {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-group {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}
/* 
.edit_gruppo .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_gruppo .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_gruppo ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
} */


.w-container-edit-group .select-disabled, .item-select-disabled ion-label {
    opacity: 1;
}


</style>
